<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="7">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'colis'"
          :AllResponse="'allcolis'"
          :title="'Liste des chargements'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Chargements'"
          :sheetname="'Chargements'"
          @open="OpenColisForm"
          @rowselect="ColisChange"
          :showedit="false"
          :Add="editer"
          :del="editer"
          :del_disable="detail_list.length > 0"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="6" md="5" v-if="colis.id">
        <listitems
          :laoding="loading"
          :list="detail_list"
          :title="detailtitle"
          :headers="detailheaders"
          :master="false"
          :key="kl"
          :add="false"
          :del="false"
          :btn_update="editer && colis.id > 0 && colis.statut_id == '21'"
          :showedit="false"
          :valid="valider && detail_list.length > 0 && colis.statut_id == '21'"
          @open="OpenDetailForm"
          @valider="valider_colis"
          :print="detail_list.length > 0 && colis.statut_id == '22'"
          @print="printcolis"
        >
        </listitems>
      </v-col>
    </v-row>

    <colisform
      :item="colis"
      :key="kcolis"
      :showForm="!isColisClosed"
      @close="closeColisForm"
      @change="ColisChange"
      :editer="editer"
      :cmds_list="cmds_list"
    >
    </colisform>
    <formlist
      :title="detailtitle"
      :list="form_list"
      :showform="!isListClose"
      :listheaders="detailheaders"
      :key="frml"
      @close="closeList"
      :Total="true"
      :master="true"
      :width="'1400px'"
      :multiple="true"
      :radio="false"
      :cmd_id="colis.cmd_id"
      :btn_save="true"
      :laoding="listload"
      :btn_save_dis="false"
      @save="saveList"
    >
    </formlist>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
let onepx;

import jsPDF from "jspdf";

import COLIS from "../graphql/Product/COLIS.gql";
import DELETE_COLIS from "../graphql/Product/DELETE_COLIS.gql";
import UPDATE_COLIS from "../graphql/Product/UPDATE_COLIS.gql";
import ALLCOLIS from "../graphql/Product/ALLCOLIS.gql";
import ALLATTRIBUTS from "../graphql/Template/ALLATTRIBUTS.gql";
import ALLVALUES from "../graphql/Template/ALLVALUES.gql";
import ACTUAL_CMDS from "../graphql/Mouvment/ACTUAL_CMDS.gql";
import PRODUCT_LIST_UPDATE from "../graphql/Product/PRODUCT_LIST_UPDATE.gql";
import PRODUCT_LIST from "../graphql/Product/PRODUCT_LIST.gql";
import { myLogo } from "print/data.js";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    colisform: () => import("../components/ColisForm.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    listitems: () => import("../components/ListItems.vue"),
    formlist: () => import("../components/FormList.vue"),
  },

  data: () => ({
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    listload: false,
    isListClose: true,
    editer: false,
    valider: false,
    aff: 0,
    kcolis: 100,
    kl: 200,
    frml: 300,
    selitem: {},
    headers: [
      {
        text: "Code",
        value: "code",
        enum: "CODE",
        slot: "href",
        selected: true,
      },

      {
        text: "Date",
        value: "date_colis",
        enum: "DATE_COLIS",
        selected: true,
      },
      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        enum: "TIERS_NAME",
        selected: true,
      },
      {
        text: "Commande",
        align: "text-left",
        value: "cmd_label",
        enum: "CMD_LABEL",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        enum: "QTE",
        align: "end",
        slot: "cur",
        selected: true,
      },
      {
        text: "Nombre",
        value: "productcount",
        selected: true,
      },
      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        align: "text-center",
        expire: true,
        selected: true,
      },
    ],

    isColisClosed: true,
    colis: {},
    changed: false,
    loading: false,
    cmds_list: [],
    attributes: [],
    values: [],
    form_list: [],
    print: false,
    postes: [
      { id: "1", poste: "Poste 1" },
      { id: "2", poste: "Poste 2" },
      { id: "3", poste: "Poste 3" },
      { id: "4", poste: "Normal" },
    ],
  }),

  computed: {
    Qselect() {
      return COLIS;
    },
    Qselectall() {
      return ALLCOLIS;
    },
    Qdelete() {
      return DELETE_COLIS;
    },
    detailtitle() {
      return this.colis.product_name
        ? "Details " + this.colis.product_name
        : "";
    },
    detail_list: function () {
      return this.colis.id > 0 ? this.colis.colislist : [];
    },
    colis_attributs() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
    variante_attributs() {
      return this.attributes.filter((elm) => elm.type_attr == "variante");
    },
    detailheaders() {
      let headers = [];
      let dh = [
        {
          text: "N°",
          value: "no",
          selected: true,
          totlib: true,
          width: 10,
          align: "center",
        },

        {
          text: "Qte.",
          value: "qte",
          selected: true,
          align: "end",
          slot: "cur",
          total: "qte",
          width: "auto",
          dec: 2,
        },
        {
          text: "Commande",
          value: "cmd_name",
          selected: true,
          align: "center",
          hiden: true,
        },
        {
          text: "Date recpt",
          value: "date_rcpt",
          selected: true,
          align: "center",
          hiden: true,
        },
        {
          text: "Etat",
          value: "statut",
          selected: true,
          align: "center",
          expire: true,
          hiden: true,
        },
        {
          text: "Comment",
          value: "comment",
          selected: true,
          hiden: true,
        },
      ];
      dh.forEach((element) => {
        if (!this.isListClose) element.hiden = false;
        headers.push(element);
      });

      headers[1].text = this.colis.unit_name;
      headers[1].dec = this.colis.decimal;
      let colis_attributs = this.colis.colisattributs
        ? this.colis.colisattributs
        : [];
      var doc = new jsPDF("l");
      onepx = doc.internal.pageSize.width / 100;
      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];
        let i = this.colis_attributs.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let elm = {
            text: this.colis_attributs[i].caption
              ? this.colis_attributs[i].caption
              : this.colis_attributs[i].name,
            value: "ref" + index,
            selected: true,
            width:
              this.colis_attributs[i].width && this.print
                ? this.colis_attributs[i].width * onepx
                : null,
            align:
              this.colis_attributs[i].align && this.print
                ? this.colis_attributs[i].align
                : null,
          };
          headers.splice(index + 1, 0, elm);
        }
      }

      return headers;
    },
  },
  watch: {},
  created() {},

  mounted() {
    this.get_data();

    this.editer =
      this.$store.state.auth.includes("02050") || this.$store.state.isadmin;

    this.valider =
      this.$store.state.auth.includes("03050") || this.$store.state.isadmin;
  },

  methods: {
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    printcolis() {
      let head = [];
      let columns = [];
      let tot_width = 0;
      this.print = true;
      //data formating
      let entite_name = this.$store.state.me.entite.name;

      let columnStyles = {};
      var doc = new jsPDF("l");
      onepx = doc.internal.pageSize.width / 100;
      var dataimg = myLogo;
      doc.addImage(dataimg, "png", 15, 15, 15, 15);
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text(entite_name, 31, 20);
      doc.setFont("helvetica", "normal");
      doc.text("DIVISION COMMERCIALE", 31, 25);
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text("BORDEREAU DE CHARGEMENT", 50, 40);
      doc.setFontSize(14);
      doc.setFont("helvetica", "normal");
      doc.text("N° " + (this.colis.code ? this.colis.code : ""), 55, 45);
      doc.text("Du : " + this.datefr(), 100, 45);

      let items = [];
      //data formating

      for (let index = 0; index < this.detail_list.length; index++) {
        let item = {};
        this.detailheaders
          .filter((elm) => elm.hiden != true)
          .forEach((elm) => {
            if (elm.value == "no") {
              item[elm.value] = index + 1;
            }
            if (elm.slot == "cur22") {
              item[elm.value] = this.numberWithSpace(
                parseFloat(this.detail_list[index][elm.value]).toFixed(
                  elm.dec ? elm.dec : 2
                )
              );
            } else if (
              elm.slot == "cur" &&
              this.detail_list[index][elm.value]
            ) {
              item[elm.value] = this.numberWithSpace(
                parseFloat(this.detail_list[index][elm.value]).toFixed(
                  elm.dec ? elm.dec : 2
                )
              );
            } else if (this.detail_list[index][elm.value])
              item[elm.value] = this.detail_list[index][elm.value];
          });
        items.push(item);
      }

      this.detailheaders.forEach((element) => {
        if (element.hiden != true) {
          head.push({
            content: element.text,
            styles: {
              halign:
                element.align == "end"
                  ? "right"
                  : element.align == "center"
                  ? "center"
                  : "left",
            },
          });

          columns.push({ dataKey: element.value });

          tot_width =
            tot_width + (typeof element.width == "number" ? element.width : 0);
          columnStyles[element.value] = {
            cellWidth: element.width ? element.width : null,
            valign: "middle",
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
          };
        }
      });
      doc.autoTable({
        margin: { top: 55, bottom: 10 },
        headStyles: {
          halign: "center",
          valign: "middle",
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineWidth: 0.3,
          lineColor: [0, 0, 0],
        },
        columnStyles: columnStyles,
        bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
        head: [head],
        body: items,
        columns: columns,
        showHead: "everyPage",
        showFoot: "everyPage",
        theme: "grid",
        tableWidth: 200,
      });

      let foot = [];
      foot.push({
        libelle: "TOTAL",
        value: this.numberWithSpace(
          this.colis.qte.toFixed(this.colis.decimal ? this.colis.decimal : 2)
        ),
      });

      columns = [];
      columns.push({ dataKey: "libelle" });
      columns.push({ dataKey: "value" });
      doc.autoTable({
        margin: { left: 15 },
        body: foot,
        columnStyles: {
          0: { halign: "left", cellWidth: tot_width },
          1: {
            halign: "right",
            cellWidth: "auto",
            fontStyle: "bold",
            fontSize: 12,
          },
        },
        bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
        columns: columns,
        theme: "grid",
        styles: {
          valign: "middle",
          fontSize: 12,
          fillColor: [255, 255, 255],
        },
        tableWidth: 200,
      });

      //info commande
      doc.setFontSize(12);
      let i = this.postes.findIndex((elm) => elm.id == this.colis.poste);
      let poste = "";
      if (i >= 0) poste = this.postes[i].poste;

      foot = [];
      columns = [];
      columns.push({ dataKey: "libelle" });
      columns.push({ dataKey: "value" });
      foot.push({
        libelle: "Poste : ",
        value: poste,
      });
      foot.push({
        libelle: "Heure : ",
        value: this.colis.date_colis.slice(10),
      });
      doc.autoTable({
        startY: 15,
        margin: { top: 15, left: 220 },
        body: foot,
        columnStyles: {
          0: { halign: "left", cellWidth: 30 },
          1: {
            halign: "left",
            cellWidth: "auto",
            fontStyle: "bold",
            fontSize: 12,
          },
        },
        bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
        columns: columns,
        theme: "grid",
        styles: {
          valign: "middle",
          fontSize: 12,
          fillColor: [255, 255, 255],
        },
        tableWidth: 70,
      });
      foot = [];
      foot.push({
        libelle: "Code : ",
        value: this.colis.cmd_code,
      });
      foot.push({
        libelle: "Client : ",
        value: this.colis.tier_name,
      });
      foot.push({
        libelle: "Commande : ",
        value: this.colis.cmd_label,
      });

      this.colis.variantattributs.forEach((element) => {
        let i = this.values.findIndex((elm) => elm.id == element);

        if (i >= 0) {
          foot.push({
            libelle: this.values[i].attribut.caption
              ? this.values[i].attribut.caption
              : this.values[i].attribut.name,
            value: this.values[i].name,
          });
        }
      });

      doc.autoTable({
        margin: { top: 15, left: 220 },
        body: foot,
        columnStyles: {
          0: { halign: "left", cellWidth: 30 },
          1: {
            halign: "left",
            cellWidth: "auto",
            fontStyle: "bold",
            fontSize: 12,
          },
        },
        bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
        columns: columns,
        theme: "grid",
        styles: {
          valign: "middle",
          fontSize: 12,
          fillColor: [255, 255, 255],
        },
        tableWidth: 70,
      });
      // info chargement
      foot = [];
      foot.push({
        libelle: "Matricule 1 : ",
        value: this.colis.matricule1,
      });
      foot.push({
        libelle: "Matricule 2 : ",
        value: this.colis.matricule2,
      });
      foot.push({
        libelle: "Etat Vehicule : ",
        value: this.colis.etat_vehicule,
      });
      foot.push({
        libelle: "Nom Chauffeur : ",
        value: this.colis.nom_chauff,
      });
      foot.push({
        libelle: "Prestataire : ",
        value: this.colis.prestataire,
      });
      doc.autoTable({
        margin: { top: 15, left: 220 },
        body: foot,
        columnStyles: {
          0: { halign: "left", cellWidth: 30 },
          1: {
            halign: "left",
            cellWidth: "auto",
            fontStyle: "bold",
            fontSize: 12,
          },
        },
        bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
        columns: columns,
        theme: "grid",
        styles: {
          valign: "middle",
          fontSize: 12,
          fillColor: [255, 255, 255],
        },
        tableWidth: 70,
      });
      ////pied de document
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text("Le représentant de " + entite_name, 20, 175);
      doc.setDrawColor(0, 0, 0);
      doc.rect(15, 170, 100, 25);
      doc.text("Le représentant du Client ", 130, 175);
      doc.rect(118, 170, 100, 25);
      doc.text("Recéption sur site ", 230, 175);
      doc.rect(220, 170, 70, 25);

      //////

      doc.setProperties({ title: "Chargement" });
      doc.output("pdfobjectnewwindow");
      this.print = false;
    },
    datefr() {
      const o_date = this.colis.date_colis
        ? this.colis.date_colis.slice(0, 10)
        : "";
      return (
        o_date.substr(8, 2) +
        "-" +
        o_date.substr(5, 2) +
        "-" +
        o_date.substr(0, 4)
      );
    },

    async maj(query, v, refresh) {
      let r;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
          }
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async get_data() {
      let v = {
        TypeScope: 1,
      };
      let r = await this.requette(ACTUAL_CMDS, v);
      if (r) this.cmds_list = r.allcmds;
      r = await this.requette(ALLATTRIBUTS, v);
      if (r) this.attributes = r.allattributes;
      r = await this.requette(ALLVALUES, v);
      if (r) this.values = r.allvalues;
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    closeList() {
      this.isListClose = true;
    },
    async load_list() {
      this.listload = true;
      this.form_list = [];
      let v = {
        produit_id: this.colis.produit_id,
      };
      let r = await this.requette(PRODUCT_LIST, v);
      if (r)
        r.product_list.forEach((element) => {
          this.form_list.push(element);
        });
      this.listload = false;
    },
    async saveList() {
      let list = [];
      list = this.form_list ? this.form_list.filter((elm) => elm.selected) : [];
      list = list.map((elm) => elm.id);

      let v = {
        colis_id: this.colis.id,
        mvms_list: list,
      };
      await this.maj(PRODUCT_LIST_UPDATE, v, true);
      this.isListClose = true;
    },
    OpenDetailForm() {
      this.load_list();
      this.frml++;
      this.isListClose = false;
    },
    closeDetailForm() {
      this.isListClose = true;
    },
    async valider_colis() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir valider ce chargement?"
        )
      ) {
        this.colis.statut_id = 22;
        let v = {
          colis: {
            id: this.colis.id,
            statut_id: this.colis.statut_id,
            date_opr: this.$store.state.today,
            write_uid: this.$store.state.me.id,
          },
        };
        await this.maj(UPDATE_COLIS, v, true);

        v = {
          operation: {
            document_type: 8,
            document_id: parseInt(this.colis.id),
            date_opr: this.$store.state.today,
            user_id: parseInt(this.$store.state.me.id),
            statut_id: 22,

            create_uid: parseInt(this.$store.state.me.id),
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
      }
    },

    ColisChange(item) {
      this.aff++;
      this.colis = item;
      this.selitem = item;
      this.kl++;
    },
    OpenColisForm(item) {
      this.colis = item;
      if (this.colis.id < 0 && this.selitem) {
        this.colis.cmd_id = this.selitem.cmd_id;
        this.colis.produit_id = this.selitem.produit_id;
      }
      this.isColisClosed = false;
      this.kcolis++;
    },
    closeColisForm(item) {
      this.selitem = item;
      this.colis = item;
      this.aff++;
      this.isColisClosed = true;
    },
  },
};
</script>
